<template>
 <el-cascader
    v-model="area"
    :options="options"
    size="small"
    filterable
    clearable
    :disabled="disabled"
    :placeholder="placeholder"
    :props="{ expandTrigger: 'click',value:'areaCode',label:'areaName',checkStrictly: true}"
    @change="handleChange"></el-cascader>
</template>

<script>
export default {
  props: {
    hval: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择省市区'
    }
  },
  data () {
    return {
      area: [],
      options: JSON.parse(localStorage.getItem('areaList'))
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.area = val
      },
      deep: true
    }
  },
  methods: {
    handleChange () {
      this.$emit('change', this.area)
    }
  }
}
</script>
