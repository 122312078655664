<template>
  <el-form-item :prop="prop" :label="label">
        <el-select
          v-model="value"
          filterable
          allow-create
          default-first-option
          :placeholder="placeholder"
          size="small"
          @change="change"
        >
          <el-option
            v-for="item in data"
            :key="item.deviceId"
            :label="item.deviceId"
            :value="item.deviceId"
          >
          </el-option>
        </el-select>
      </el-form-item>
</template>

<script>
export default {
  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hval: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择序列号'
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    hval (val) {
      this.value = val
    }
  },
  methods: {
    change () {
      this.$emit('change', this.value)
    }
  }
}
</script>
