<template>
  <div class="layout_common event_waring">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <selectComp
              placeholder="事件状态"
              :data="statusList"
              :hval="status"
              @change="statusChange"
            />
            <inputComp placeholder="事件编号" :hval="code" @blur="codeBlur" />
            <selectComp
              placeholder="故障等级"
              :data="levelList"
              :hval="level"
              @change="levelChange"
            />
            <selectComp
              placeholder="事件来源"
              :data="sourceList"
              :hval="source"
              @change="sourceChange"
            />
            <inputComp placeholder="UUID" :hval="uuid" @blur="uuidBlur" />
            <inputComp
              placeholder="序列号"
              :hval="serialNum"
              @blur="serialNumBlur"
            />
            <goodCategory
              placeholder="设备类别"
              :hval="categoryId"
              @change="categoryIdChange"
            />
            <inputComp
              placeholder="合同/订单号"
              :hval="orderCode"
              @blur="orderCodeBlur"
            />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="num" label="处理工单">
            <template v-slot="scope">
              <div
                :style="{
                  cursor: scope.row.num ? 'pointer' : 'auto',
                  color: scope.row.num ? '#385BFF' : ''
                }"
                @click="handleOrderNum(scope.row)"
              >
                {{ scope.row.num || '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            width="120"
            fixed="right"
          >
            <template v-slot="scope">
              <btnComp
                btnType="text"
                v-if="scope.row.status != 0"
                style="margin-left:10px"
                @click="edit(scope.row)"
                v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.status != 0"
                @click="send(scope.row)"
                v-permission="'派单'"
                >派单</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.status != 0"
                @click="transfer(scope.row)"
                v-permission="'转交'"
                >转交</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.status != 0 && !scope.row.handleName"
                @click="receive(scope.row)"
                v-permission="'领取'"
                >领取</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.num == 0 && scope.row.status != 0"
                @click="over(scope.row)"
                v-permission="'中止'"
                >中止</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 借用订单系统中的新增售后弹窗 -->
    <send
      :isShow="sendShow"
      @close="close"
      :eventInfo="info"
      :info="orderInfo"
      dialogType="eventSend"
    />
    <!-- 编辑 -->
    <edit :isShow="editShow" @close="close" :info="info" />
    <!-- 转交 -->
    <transfer :isShow="transferShow" @close="close" :info="info" />
    <!-- 中止弹窗 -->
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="sureOver">
      确定要中止吗？
    </dialogComp>
    <!-- 处理工单数量弹窗 -->
    <dialogComp
      title="处理工单编号"
      :isShow="numShow"
      @close="numShow = false"
      :isShowFooter="false"
    >
      <ul class="display:flex;flex-wrap: wrap;">
        <li
          v-for="item in handleOrderInfo"
          :key="item"
          style="line-height:30px"
        >
          {{ item }}
        </li>
      </ul>
    </dialogComp>
  </div>
</template>

<script>
import goodCategory from '@/views/components/goodCategory'
import send from '@/views/order-system/order-list/add'
import edit from './edit'
import transfer from './transfer'
import {
  eventList,
  eventOver,
  eventReceive,
  orderList,
  handleOrderList
} from '@/api'
export default {
  name: 'waring-event',
  components: {
    goodCategory,
    send,
    edit,
    transfer
  },
  data () {
    return {
      statusList: [
        { label: '待处理', value: 1 },
        { label: '已派单', value: 2 },
        { label: '已完成', value: 3 },
        { label: '已中止', value: 0 }
      ],
      levelList: [
        { label: '致命', value: 1 },
        { label: '严重', value: 2 },
        { label: '较重', value: 3 },
        { label: '一般', value: 4 },
        { label: '轻微', value: 5 }
      ],
      sourceList: [{ label: '监控告警', value: 1 }],
      sendShow: false,
      editShow: false,
      hintShow: false,
      transferShow: false,
      numShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      status: null,
      code: '',
      level: null,
      source: null,
      uuid: '',
      serialNum: '',
      categoryId: '',
      orderCode: '',
      date: [],
      startTime: '',
      endTime: '',
      orderInfo: {}, // 订单信息
      handleOrderInfo: [], // 工单信息
      info: {},
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'statusName', label: '事件状态' },
        { prop: 'code', label: '事件编号' },
        { prop: 'createdTime', label: '事件时间' },
        { prop: 'sourceName', label: '事件来源' },
        { prop: 'levelName', label: '事件等级' },
        { prop: 'remark', label: '事件描述' },
        { prop: 'uuid', label: 'UUID' },
        { prop: 'serialNum', label: '序列号' },
        { prop: 'categoryName', label: '设备类别' },
        { slot: 'num' },
        // { prop: 'num', label: '处理工单' },
        { prop: 'customerName', label: '客户' },
        { prop: 'orderCode', label: '合同/订单号' },
        { prop: 'handleName', label: '售后' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        status: this.status,
        code: this.code,
        level: this.level,
        source: this.source,
        uuid: this.uuid,
        serialNum: this.serialNum,
        categoryId: this.categoryId,
        orderCode: this.orderCode,
        startTime: this.startTime,
        endTime: this.endTime
      }
      eventList(params).then(res => {
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = totalCount
      })
    },
    statusChange (val) {
      this.status = val
    },
    codeBlur (val) {
      this.code = val
    },
    levelChange (val) {
      this.level = val
    },
    sourceChange (val) {
      this.source = val
    },
    uuidBlur (val) {
      this.uuid = val
    },
    serialNumBlur (val) {
      this.serialNum = val
    },
    categoryIdChange (val) {
      this.categoryId = val ? val.resultId : ''
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    // 获取工单信息
    getWorkOrderList ({ id }) {
      const params = {
        faultIncidentId: id
      }
      handleOrderList(params).then(res => {
        this.handleOrderInfo = Object.freeze(res.data)
        this.numShow = true
      })
    },
    // 获取订单信息
    async getOrderList (orderId, deviceId) {
      const params = {
        page: 1,
        size: 10,
        orderId,
        deviceId
      }
      const res = await orderList(params)
      if (res.code === 0) {
        this.orderInfo = res.result.records[0]
        this.sendShow = true
      }
    },
    // 处理工单
    handleOrderNum (info) {
      info.num && this.getWorkOrderList(info)
    },
    // 编辑
    edit (info) {
      this.info = info
      this.editShow = true
    },
    // 派单
    send (info) {
      this.info = info
      // 通过订单号和序列号查选订单信息
      this.getOrderList(info.orderCode, info.serialNum)
    },
    // 转交
    transfer (info) {
      this.info = info
      this.transferShow = true
    },
    // 领取
    receive ({ id }) {
      eventReceive({ id }).then(_ => {
        this.$message.success('领取成功')
        this.getList()
      })
    },
    // 中止
    over (info) {
      this.info = info
      this.hintShow = true
    },
    sureOver () {
      eventOver({ id: this.info.id }).then(_ => {
        this.$message.success('中止成功')
        this.close()
      })
    },
    close () {
      this.sendShow = false
      this.editShow = false
      this.hintShow = false
      this.transferShow = false
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = null
      this.code = ''
      this.level = null
      this.source = null
      this.uuid = ''
      this.serialNum = ''
      this.categoryId = ''
      this.orderCode = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.search_left {
  flex-wrap: wrap;
  > div {
    margin-bottom: 10px;
  }
  .el-button {
    margin-bottom: 10px;
  }
}
</style>
