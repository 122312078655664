<template>
  <dialogComp
    title="编辑"
    width="640px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <ul class="info_box">
      <li>
        <div>事件编号：{{info.code}}</div>
        <div>故障等级：{{info.levelName}}</div>
      </li>
      <li>
        <div>报障时间：{{info.createdTime}}</div>
        <div>事件状态：{{info.statusName}}</div>
      </li>
      <li>
        <div>客户：{{info.customerName}}</div>
        <div>订单/合同：{{info.orderCode}}</div>
      </li>
      <div style="border-bottom:1px solid #ddd"></div>
      <li>
        <div>UUID：{{info.uuid}}</div>
        <div>序列号：{{info.serialNum}}</div>
      </li>
      <li>
        <div>设备类别：{{info.categoryName}}</div>
      </li>
      <li>
        <span>事件描述：</span>
        <inputComp inputType="textarea" :hval="remark" @blur="remarkBlur" />
      </li>
    </ul>
  </dialogComp>
</template>
<script>
import { eventEdit } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      remark: ''
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.remark = this.info.remark
      }
    }
  },
  methods: {
    remarkBlur (val) {
      this.remark = val
    },
    close () {
      this.remark = ''
      this.$emit('close')
    },
    sure () {
      const params = {
        id: this.info.id,
        remark: this.remark
      }
      eventEdit(params).then(_ => {
        this.$message.success('编辑成功')
        this.close()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .info_box {
    li {
      display: flex;
      line-height: 32px;
      >div {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
</style>
