// 处理时效
export const handleAgingList = [
  { label: '待处理（<=4小时）', value: 1 },
  { label: '待处理（>4小时）', value: 2 },
  { label: '待处理（全部）', value: 3 },
  { label: '已处理（<=4小时）', value: 4 },
  { label: '已处理（>4小时）', value: 5 },
  { label: '已处理（全部）', value: 6 }
]
// 响应时效
export const responseAgingList = [
  { label: '待响应（<=30分钟）', value: 1 },
  { label: '待响应（>30分钟）', value: 2 },
  { label: '待响应（全部）', value: 3 },
  { label: '已响应（<=30分钟）', value: 4 },
  { label: '已响应（>30分钟）', value: 5 },
  { label: '已响应（全部）', value: 6 }
]
// 回访时效
export const visitAgingListList = [
  { label: '待回访（0~24小时）', value: 1 },
  { label: '待回访（24~48小时）', value: 2 },
  { label: '待回访（48~72小时）', value: 3 },
  { label: '待回访（>72小时）', value: 4 }
]
// 上门时效
export const sceneAgingList = [
  { label: '待上门（<=4小时）', value: 1 },
  { label: '待上门（>4小时）', value: 2 },
  { label: '待上门（全部）', value: 3 },
  { label: '已上门（<=4小时）', value: 4 },
  { label: '已上门（>4小时）', value: 5 },
  { label: '已上门（全部）', value: 6 }
]
// 回访人状态
export const assignServiceList = [
  { label: '待指派', value: 1 },
  { label: '已指派', value: 2 }
]
// 业务角色
export const businessRoleList = [
  { label: '售后', value: 'aftermarket' },
  { label: '客服', value: 'service' },
  { label: '调度', value: 'dispatch' },
  { label: '财务', value: 'finance' },
  { label: '商家', value: 'store' }
]
// 业务角色(用于系统管理-人员管理)
export const businessRoleListToSys = [
  { label: '售后', value: 'aftermarket' },
  { label: '客服', value: 'service' },
  { label: '调度', value: 'dispatch' },
  { label: '财务', value: 'finance' },
  { label: '销售', value: 'sale' },
  { label: '品控', value: 'qualitycontrol' },
  { label: '风控', value: 'riskmanagement' }
]
// 业务角色（去掉财务）
export const businessRoleListExceptFinance = [
  { label: '售后', value: 'aftermarket' },
  { label: '客服', value: 'service' },
  { label: '调度', value: 'dispatch' },
  { label: '商家', value: 'store' }
]
// 用户状态
export const userStatusList = [
  { label: '正常', value: 1 },
  { label: '冻结', value: 2 },
  { label: '删除', value: 3 }
]
// 新增售后-下步处理
export const nextHandleList = [
  {
    label: '转客服领取',
    value: 10
  },
  {
    label: '转售后领取',
    value: 20
  },
  {
    label: '转商家领取',
    value: 30
  },
  {
    label: '转调度领取',
    value: 40
  }
]
// 新增售后-来源
export const sourceList = [
  {
    label: '400电话',
    value: 1
  },
  {
    label: '销售转述',
    value: 2
  },
  {
    label: '微信联系',
    value: 3
  },
  {
    label: '在线客服',
    value: 4
  },
  {
    label: '扫码报修',
    value: 5
  }
]
// 上门处理和我的上门-上门状态
export const sceneStatusList = [
  { label: '待受理', value: 10 },
  { label: '待派单', value: 20 },
  { label: '待接单', value: 30 },
  { label: '待出发', value: 40 },
  { label: '待到达', value: 50 },
  { label: '已到达', value: 60 },
  { label: '申请验收', value: 70 },
  { label: '上门中止(有费用)', value: 900 },
  { label: '上门中止(无费用)', value: 0 },
  { label: '已验收', value: 1000 }
]
// 上门处理和我的上门-工单状态
export const orderStatusList = [
  { label: '处理中', value: 1 },
  { label: '待客服回访', value: 2 },
  { label: '客服已回访', value: 3 }
]
// 新增售后、报修弹窗的业务类型  工单类型
export const orderTypeList = [
  { label: '租赁业务', value: 1 },
  { label: '增值服务', value: 2 },
  { label: '大客业务', value: 3 }
]
// 对应的工单类型
export const orderTypeChiList1 = [
  { label: '报修单', value: 'zl-bx' },
  { label: '安装单', value: 'zl-az' },
  { label: '巡检单', value: 'zl-xj' }
]
export const orderTypeChiList2 = [
  { label: '服务单', value: 'zz-fw' },
  { label: '巡检单', value: 'zz-xj' }
]
export const orderTypeChiList3 = [
  { label: '集成单', value: 'dk-jc' },
  { label: '安装单', value: 'dk-az' },
  { label: '巡检单', value: 'dk-xj' }
]
// ==========品控===========
// 品控的工单状态
export const orderStatusListToQC = [
  { label: '待领取', value: 1 },
  { label: '处理中', value: 2 },
  { label: '待回访', value: 3 },
  { label: '已完结', value: 4 }
]
// 商家的工单管理
export const storeSettlementTypeList = [
  { label: '平台托管', value: 1 },
  { label: '商家自营', value: 2 }
]
// 报修次数
export const repairNumList = [
  { label: '报修次数=0', value: 0 },
  { label: '报修次数=1', value: 1 },
  { label: '报修次数>1', value: 2 }
]
// 品控状态
export const statusListToQC = [
  { label: '待复查', value: 0 },
  { label: '待审核', value: 1 },
  { label: '待确认', value: 2 },
  { label: '待结算', value: 3 },
  { label: '已结算', value: 4 }
]
// 处罚金额
export const priceTypeList = [
  { label: '处罚金额:0', value: 1 },
  { label: '处罚金额:1-200', value: 2 },
  { label: '处罚金额:>200', value: 3 }
]
// 租赁时长
export const durationTypeList = [
  { label: '1期', value: 1 },
  { label: '2期', value: 2 },
  { label: '3期', value: 3 },
  { label: '4期', value: 4 },
  { label: '5期', value: 5 },
  { label: '6期', value: 6 },
  { label: '7期', value: 7 },
  { label: '8期', value: 8 },
  { label: '9期', value: 9 },
  { label: '10期', value: 10 },
  { label: '11期', value: 11 },
  { label: '12期', value: 12 },
  { label: '>12期', value: 13 },
  { label: '1-6期', value: 14 },
  { label: '7-12期', value: 15 }
]
// 退单方式
export const chargebackTypeList = [
  { label: '全退', value: 1 },
  { label: '半退', value: 2 }
]
// 有无违约金
export const liquidatedDamagesList = [
  { label: '有违约金', value: 1 },
  { label: '无违约金', value: 2 }
]
// 换产品再租
export const chargebackAgainList = [
  { label: '不再租', value: 1 },
  { label: '换产品再租', value: 2 }
]
// 订单状态-退单
export const orderStatusListToChargeback = [
  { label: '租赁中', value: 2 },
  { label: '租赁完成', value: 3 },
  { label: '已回收待商家结算', value: 14 },
  { label: '已回收待用户补缴', value: 15 }
]
// 统计模式
export const statModelList = [
  { label: '按设备数量', value: 1 },
  { label: '按订单数量', value: 2 },
  { label: '按客户数量', value: 3 }
]
// 退单模式
export const chargebackModelList = [
  { label: '租期满退', value: 1 },
  { label: '提前退', value: 2 }
]
// 报障量统计-orderTypeList
export const orderTypeListToDevice = [
  { label: '报修设备数量', value: 1 },
  { label: '报修工单数量', value: 2 }
]
// 报障量统计-统计纬度
export const statisticsTypeListToDevice = [
  { label: '是否上门', value: 1 },
  { label: '报修来源', value: 2 },
  { label: '二次处理', value: 3 },
  { label: '客户催促', value: 4 },
  { label: '客户投诉', value: 5 },
  { label: '客情单', value: 6 },
  { label: '业务类型', value: 7 }
]
