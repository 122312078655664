<template>
  <dialogComp title="转交" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="转交人员:" prop="userId">
        <handleUser placeholder="请选择转交人员" :hval="formData.userId" businessRole="aftermarket,service" @change="userIdChange" />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>

<script>
import handleUser from '@/views/components/handleUser'
import { eventTransfer } from '@/api'
export default {
  components: {
    handleUser
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        userId: ''
      },
      rules: {
        userId: [
          { required: true, message: '请选择转派人员', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    userIdChange (val) {
      this.formData.userId = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            userId: this.formData.userId,
            id: this.info.id
          }
          eventTransfer(params).then(res => {
            this.$message.success('转交成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
