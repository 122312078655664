<template>
  <el-form-item :label="label" :prop="prop">
    <el-select
      v-model="code"
      size="small"
      :placeholder="placeholder"
      @change="change"
    >
      <el-option
        v-for="item in list"
        :key="item[optionsParams.value]"
        :label="item[optionsParams.label]"
        :value="item[optionsParams.value]"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import { dictList } from '@/api'
export default {
  props: {
    // 可以配置label  value
    optionsParams: {
      type: Object,
      default: () => ({ value: 'code', label: 'name' })
    },
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    // 父级code
    parentCode: {
      type: Number,
      require: null
    },
    hval: {
      type: [Number, String]
    },
    placeholder: {
      type: String,
      default: '请选择'
    }
  },
  data () {
    return {
      code: null,
      list: []
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.code = val
      }
    }
  },
  mounted () {
    this.getList(this.parentCode)
  },
  methods: {
    getList (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      dictList(params).then(res => {
        this.list = res.data.list
      })
    },
    change (val) {
      let item = {}
      if (val) {
        const { code, name, configValue } = this.list.find(item => item[this.optionsParams.value] === val)
        item = { code, name, configValue }
      } else {
        // 点击清空按钮
        item = {
          code: '',
          name: '',
          configValue: ''
        }
      }
      this.$emit('change', item)
    }
  }
}
</script>
