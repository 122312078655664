
<template>
  <el-select
    @change="change"
    :multiple="multiple"
    collapse-tags
    :filterable="isfilterable"
    v-model="value"
    clearable
    :placeholder="placeholder"
    :size="size"
  >
    <el-option key="" value="" label="全部" v-if="isShowAll"></el-option>
    <el-option
      v-for="item in data"
      :key="item.resultId"
      :label="item.resultName"
      :value="item.resultId"
    ></el-option>
  </el-select>
</template>
<script>
import { goodsCategoryList } from '@/api'
export default {
  props: {
    // 是否支持模糊查询
    isfilterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '商品类目'
    },
    hval: {
      type: [String, Number]
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data () {
    return {
      value: null,
      data: []
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.value = val
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      goodsCategoryList().then(res => {
        if (res.code === 0) {
          this.data = res.result
        }
      })
    },
    change (val) {
      const item = this.data.find(item => item.resultId === val)
      this.$emit('change', item)
    }
  }
}
</script>
