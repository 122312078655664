
<template>
  <el-select
    @change="change"
    @input="input"
    :multiple="multiple"
    :filterable="isfilterable"
    v-model="value"
    clearable
    :placeholder="placeholder"
    :size="size"
  >
    <el-option key="" value="" label="全部" v-if="isShowAll"></el-option>
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.realName"
      :value="businessRole === 'store'?item.userId:item.id"
    ></el-option>
  </el-select>
</template>
<script>
import { userList, supplierUserList } from '@/api'
export default {
  props: {
    // 是否支持模糊查询
    isfilterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    hval: {
      type: [String, Number, Array]
    },
    size: {
      type: String,
      default: 'small'
    },
    businessRole: {
      type: String,
      default: ''
    },
    storeId: {
      type: [String, null],
      default: null
    }

  },
  data () {
    return {
      value: this.multiple ? [] : '',
      data: []
    }
  },
  watch: {
    hval: {
      handler (val) {
        this.value = val
      }
    },
    businessRole: {
      immediate: true,
      handler (val) {
        val && this.getList(val, this.storeId)
      }
    }
  },
  mounted () {

  },
  methods: {
    // 注意  如果请求商家人员  就需要请求supplierUserList这个接口  其他角色请求userList这个接口
    getList (businessRole, storeId) {
      if (businessRole === 'store') {
        const storeParams = {
          curPage: 1,
          pageSize: 100,
          storeId
        }
        supplierUserList(storeParams).then(res => {
          this.data = res.data.list
        })
        return
      }
      const params = {
        curPage: 1,
        pageSize: 100,
        status: 1,
        workStatus: 1,
        businessRole
      }
      userList(params).then(res => {
        this.data = res.data.list
      })
    },
    change () {
      this.$emit('change', this.value)
    },
    input () {
      this.$emit('input', this.value)
    }
  }
}
</script>
